@font-face {
  font-family: "Kyiv";
  src: url("./fonts/KyivTypeTitling-VarGX.ttf") format("truetype");
}

@font-face {
  font-family: "Authentic Sans";
  src: url("./fonts/6ea5436.woff2") format("woff2");
}

@font-face {
  font-family: "Grotesk";
  src: url("./fonts/ae617ed.woff2") format("woff2");
}


:root {
  --background-color: #666666;
  /* --background-color: rgb(8, 8, 8); */
  --body-font: "Authentic Sans", Helvetica, sans-serif;
  --emph-font: "Grotesk", Helvetica, sans-serif;
  --header-font: "Kyiv", sans-serif;
  --title-font: "Kyiv";
  --text-accent: #fff;
  --bg-accent: #b3564a;
  --columns: 4;
  --insetMaxWidth: 60%;
  --insetMaxWidth-med: 80%;
  --insetMaxWidth-sm: 90%;
  --page-gutter: 5vw;
  --accent1: #fff;
  --accent2: #000;
  --accent3: #b3564a;
  --accent4: #b3564a;
  /* --accent4: #ffd280; */
  --butMargin: 40px;
  --butMargin-med: 30px;
  --butMargin-sm: 10px;
  
}

@custom-media --mobile (max-width: 1080px);
@custom-media --tablet (min-width: 600px);
@custom-media --desktop (min-width: 768px);
@custom-media --huge (min-width: 1500px);

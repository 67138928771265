/* Acts in Translation */

a.linky {
  color: var(--accent4);
  text-shadow: 2px 2px 10px var(--accent1);
}

a.linky:hover {
  text-shadow: 2px 2px 10px var(--accent4);
  color: var(--accent1) !important;
}

#AITLocA,
#AITLocB {
  font-size: 26px;
  font-weight: 100;
  font-family: var(--title-font);
  font-variation-settings: "wght"100;
  opacity: 1;
}

#AITLocA.hidden,
#AITLocB.hidden {
  opacity: 0;
}

#AITTitle {
  align-self: flex-end;
}

#AITBack {
  align-self: flex-start;
}

#AITNavs {
  width: 90vw;
  height: 100%;
  position: absolute;
  display: flex;
  /* align-items: center; */
  /* justify-content:left; */
}

#AITContainer {
  background: var(--background-color);
  background-size: contain;
  width: 100vw;
  height: 100%;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.9s ease 0s;
}

.AITHide {
  pointer-events: none;
  opacity: 0 !important;
}

#AITInfoOverlay {
  background: var(--background-color);
  width: 100%;
  min-height: 100%;
  overflow: auto;
  padding-top: 85px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.9s ease 0s;
  max-height: 100vh;
}


#AITButTextOpen {
  display: block;
}

#AITButTextClose {
  display: none;
}

.AITFadedIn {
  opacity: 1 !important;
}

#AITWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#AITGridBox {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 3fr 1fr 1fr;
  width: 100%;
  height: 100%;
}

#AITVidBox {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px 0px 20px 0px; */
  grid-column: 1/10;
  grid-row: 1/6;
}

#AITVidWrapper {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 3fr 1fr;
}

#AITCityLabA {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/10;
  grid-row: 2/3;
  z-index: 100;
}

#AITCityLabB {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/10;
  grid-row: 4/5;
  z-index: 100;
}

#AITVidElem {
  grid-row: 1/4;
  grid-column: 1/4;
  max-width: 100%;
  height: 100%;
  display:none;
}

#AITVidElem_sq {
  grid-row: 1/4;
  grid-column: 1/4;
  max-width: 100%;
  height: 100%;
  display:block;
}

#AITTitleBox {
  grid-row: 3/4;
  grid-column: 1/4;
  display: flex;
  z-index: 1000;
  align-self: center;
  justify-self: center;
  max-width: 100%;
  overflow: visible;
  margin: 0;
}

#AITTitleBox p {
  color: var(--accent1);
  font-size: 16px;
  font-family: "Arial", sans-serif;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  text-shadow: 3px 5px 5px #000000, -3px 3px 5px #000000;
}

#AITSub {
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#AITCountDown {
  grid-row: 5/6;
  grid-column: 1/10;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: 24px;
  opacity: .2;
  transition: all 0.9s ease 0s;
}

#AITCountTitle {
  font-size: 18px;
}

#AITCountDown.hidden {
  opacity: 0 !important;
}

#AITSvgBox {
  min-width: 100vw;
  min-height: 150vh;
  position: absolute;
  filter: blur(14px);
  background: url("../img/diagramv4.png") no-repeat center center;
  background-size: cover;
  opacity: 0.15;
}


#AITButtonBack,
#AITButton {
  z-index: 200;
  text-align: center;
  opacity: 0.5;
  transition: all 0.9s ease 0s;
}

#AITButton {
  margin: var(--butMargin-sm) var(--butMargin-sm) 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

#AITButtonBack {
  margin: 5vw 0px 0px 5vw;
  position: absolute;
  top: 0;
  left: 0;
}

#AITButton:hover,
#AITButtonBack:hover {
  opacity: 1;
  background: var(--accent4);
  cursor: pointer;
}

#AITButton span,
#AITButtonBack span {
  padding: 10px 15px 10px 15px;
  border: 1px solid var(--accent1);
}

#AITButtonBack span {
  display: inline-block;
}

#AITButton span:hover,
#AITButtonBack span:hover {
  border: 1px solid var(--accent1);
}

@media (min-width: 320px) and (orientation: landscape) {
  #AITWrapper {
    width: 100%;
    height: 100%;
  }

  #AITVidAIT {
    max-height: 90vh;
  }

  #AITCityLabA {
    grid-column: 1/5;
    grid-row: 1/2;
  }

  #AITCityLabB {
    grid-column: 6/10;
    grid-row: 5/6;
  }

  #AITLocA,
  #AITLocB {
    font-size: 1.05rem;
    color: rgb(255, 255, 255);
  }
}

@media (min-width: 320px) {
  #AITTitleBox p {
    font-size: 14px;
    color: var(--accent1);
  }
}

@media (min-width: 768px) {

  #AITLocA,
  #AITLocB {
    font-size: 1.3em;
  }

  #AITVidBox {
    grid-column: 1/10;
    grid-row: 1/6;
  }

  #AITTitleBox p {
    font-size: 20px;
  }

  #AITCityLabA {
    grid-row: 1/3;
    align-items: flex-end;
    padding-bottom:55px;
  }

  #AITCityLabB {
    grid-row: 4/6;
    align-items: flex-start;
    padding-top:55px;
  }
  #AITVidElem {
    display:block;
  }
  
  #AITVidElem_sq {
    display:none;
  }

  #AITButtonBack {
    margin:  0px 0px var(--butMargin) var(--butMargin);
    top: unset;
    left: 0;
    bottom:0;
  }

  #AITButton {
    margin: var(--butMargin-med) var(--butMargin-med) 0px 0px ;

  }



}

@media (min-width: 1200px) {
  #AITContainer {
    padding:0;
  }
  #AITCityLabA {
    grid-column: 1/4;
    grid-row: 2/5;
    align-items: center;
    padding-bottom:0;
  }

  #AITTitleBox p {
    font-size: 24px;
    /* -webkit-text-stroke: 2px black;
        paint-order: stroke fill;
        font-weight: bold; */
  }

  #AITCityLabB {
    grid-column: 7/10;
    grid-row: 2/5;
    align-items: center;
    padding-top:0;
  }

  #AITBackDiv {
    grid-column: 1/3;
  }

  #AITSvgBox {
    min-height: 200vh;
  }

  #AITButton span,
  #AITButtonBack span {
    font-size: 1.2em;
  }

  #AITCountTitle {
    font-size: 24px;
  }

  #AITCountTxt {
    font-size: 32px;
    padding-bottom: 12px;
  }
  #AITButton {
    margin: 0px 0px var(--butMargin) var(--butMargin);
    position: absolute;
    top: unset;
    right:unset;
    bottom: 0;
    left: 0;
  }

}

@media (min-width: 1700px) {
  #AITCityLabA {
    grid-column: 3/4;
    grid-row: 2/5;
  }

  #AITCityLabB {
    grid-column: 7/8;
    grid-row: 2/5;
  }


}

@media (min-width: 2000px) {

  #AITLocA,
  #AITLocB {
    font-size: 1.75rem;
  }

  #AITVidBox {
    grid-column: 3/8;
    grid-row: 1/6;
  }

  #AITCityLabA {
    grid-column: 3/5;
    grid-row: 2/5;
  }

  #AITCityLabB {
    grid-column: 6/8;
    grid-row: 2/5;
  }

  #AITCountDown {
    grid-row: 4/6;
  }

}

::selection {
  background: var(--accent4);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: var(--accent4);
  /* Gecko Browsers */
}
@import "../../common/util.scss";


.container {
  position: static;
  


  // height: 100%;
  // margin-bottom: 7vh;
  // @include portrait {
  //   margin-bottom: calc(100vw/11);
  // }
}


@media (min-width: 768px) {
  .container {
    min-height: 100%;
  }
}
@import "../../common/util.scss";

.headingWrap {
    width: 100%;
}

.heading {
    width: 50px;
    margin: var(--butMargin-sm) 0px 0px var(--butMargin-sm);
    position: relative;
    top: 0;
    left: 0;
    opacity: 0.5;
    transition: opacity .9s ease 0s;
    z-index: 9999;

}

.heading:hover {
    opacity: 1;
}

@media (min-width: 768px) {
    .heading {
        position: fixed;
        margin: var(--butMargin-med) 0px 0px var(--butMargin-med);
    }
}

@media (min-width: 1024px) {
    .heading {
        width: 70px;
        
        margin: var(--butMargin) 0px 0px var(--butMargin);

    }
}
.tranSection {
    justify-content: center;
    margin: 5vw;
    font-family: var(--body-font) !important;
    font-size: 1.3em !important;
    line-height: 1.5em !important;
}
.tranWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.tranBox{
    display: inline-block;
}

.tranBox p {
    margin:0;
    padding:0 !important;
}

.dtFont {
    font-family: var(--title-font);
    font-weight: 100;
    font-variation-settings: "wght" 100 !important;
    font-size: 1.7em !important;
    line-height: 1.2em !important;
  
  }
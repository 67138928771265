@import "../../common/util";
.container {
  @include container;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  img, div {
    @include medium {
      height: 100vh;
      width: 100vw;
    }
    object-fit: contain;
  }
  div {
    flex: 1;
    margin: 0 auto;
  }
}

.shim {
  width: 100%;
  height: 100vh;
}

.fluidLayoutItem {
  @include mt(l);
}

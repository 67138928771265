.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  h1 {
    font-size: 48px;
    font-weight: 700;;
    text-align: center;
  }

}
@import '../../common/reset.css';
@import '../../common/variables.css';
body {
  margin: 0;
  font-family: var(--body-font);
  color: var(--accent2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);

  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: none;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-size: 21px;
  line-height: 1.2em;
  position: relative;
  color: var(--accent1);
  min-height: 100%;
}


ol {
  padding-left: 1.8em;
}

.sr-only {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}

.sr-only:focus, .sr-only:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  overflow:auto;
  padding:5px;
  border:4px solid yellow;
  text-align:center;
  z-index:999;
}


html {
  --scrollbarBG: rgb(155, 155, 155);
  --scrollbarBGHover: rgb(224, 224, 224);
  --thumbBG: black;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarBG) var(--thumbBG);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarBG);
  /* border-radius: 20px; */
  border: 0px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbarBGHover);
}

/* Buttons */
*::-webkit-scrollbar-button:single-button {
	width: 0;
	height: 0;
	display: none;
}

@media screen and (max-width: 767px) {
  .smallOnly {
    display: inherit !important;
  }
  
  .mediumOnly {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .smallOnly {
    display: none !important;
  }
  
  .mediumOnly {
    display: flex !important;
  }
}
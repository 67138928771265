.dtText {
  max-width: 100%;
  height:100%;
  display: flex;
  gap: 50px;
  overflow:auto !important;
  flex-direction: column;
  align-items: center;
  padding: 85px var(--page-gutter);
}

.soundOn{
  text-shadow: 2px 2px 28px var(--accent1),  2px 2px 28px var(--accent1);
}

.p0, .p1, .p2, .p3{
  font-size: 1.2em !important;
  font-smooth: 2em;
  font-family: var(--body-font);
  line-height: 1.3em !important;
  font-weight: thin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

}


.dtButWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
  cursor: pointer;
  
}

.dtButton {
  width:100%;
  display: inline-block;
  text-align: center;
  background-size: cover;
  color: var(--text-accent);
  font-family: var(--title-font);
  font-size: 1.2em !important;
  font-variation-settings: "wght" 100;
  padding: 1.25rem .75rem;
  text-decoration: none;
  line-height: 1.2em;
  border: 1px var(--accent1) solid;
  transition: all 0.7s ease-in-out 0s;
  box-shadow: 2px 2px 80px var(--accent1), 2px 2px 80px var(--accent1);
}

.dtButton:hover {
  box-shadow: 2px 2px 80px var(--accent4);

}

.audioHeading {
  font-family: var(--header-font);
  font-size: 1.4em !important;
  line-height: 1em !important;
  font-variation-settings: "wght" 100;
  text-align: center;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.audioHeading h3{
  font-weight: normal;
}


.dtPCover {
  background-size: cover;
  display: none;
  z-index: -1;
}

.dtPCover div {
  /* min-height: 100vh; */
  /* max-width: 100vw; */
  display: flex;
  opacity: 0.62;
  filter: blur(3px);
  align-items: center;
  justify-content: center;
}

.dtPCover img {
  max-height: 90vh;
  background-size: cover;
}

.dtContribCol, .dtCredits {
  font-family: var(--body-font);
  font-size: 1em !important;
  line-height: 1.2em !important;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dtInfoCol {
  font-family: var(--emph-font);
  font-size: 1em !important;
  line-height: 1.2em !important;
  text-align: left;
}

.dtFont {
  font-family: var(--title-font);
  font-variation-settings: "wght" 100;

}
@media (min-width: 420px) {

}

@media (min-width: 768px) {
  .dtButWrapper {
    width: 80%;
  }
  .p0, .p1, .p2, .p3, .p4 {
    font-size: 1.5em !important;
  }

  .dtButton{
    font-size: 1.7em !important;
  }
  .dtText{
    justify-content: center;
  }
  .audioHeading {
    font-size: 1.8em !important;
  }
  .dtContribWrapper {
    display:flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }
  .dtInfoCol {
    text-align: justify;
    font-size: 1.4em !important;
  }
  .dtContribCol, .dtCredits {
    font-size: 1.2em !important;
    line-height: 1.3em !important;
    text-align: left;
  }

}
@media (min-width: 1200px) {

  .p0, .p1, .p2, .p3, .p4 {
    font-size: 1.2em !important;
  }
  .p0 {
    grid-row: 1/5;
    grid-column: 6/11;
    padding: 0 10px;
  }

  .p1 {
    grid-row: 5/7;
    grid-column: 1/4;
    padding: 0 10px;
    text-align: left;
  }

  .p2 {
    grid-row: 4/7;
    grid-column: 8/11;
    padding: 0 10px;
    text-align: right;

  }
  .p3 {
    grid-row: 7/9;
    grid-column: 4/8;
  }

  .dtButWrapper {
    grid-column: 4/9;
    grid-row: 4/7;
  }

  .dtPCover {
    display: flex;
  }
  .dtButton{
    font-size: 1.8em;
  }
  .dtText {
    max-width: 100vw;
    display: grid;
    /* overflow: hidden; */
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(9, 1fr);
    padding-top:0;
    padding-bottom:0;
  }
  .dtContribCol, .dtCredits {
    font-size: 1.2em !important;
    line-height: 1.2em !important;
  }

}

@media (min-width: 1700px) {
  .dtButWrapper {
    grid-column: 4/9;
  }
  .dtButton{
    font-size: 1.7em !important;
    padding: 1.25rem .75rem;
    line-height: 1.2em !important;
  }
  .p0 {
    grid-column: 6/10;
    grid-row: 2/4;
    padding: 0;
  }
  .p0, .p1, .p2, .p3, .p4 {
    font-size: 1.4em !important;
  }

  .p1 {
    padding: 0 60px;
  }

  .p2 {
    padding: 0 60px;

  }
  .p3 {
    grid-row: 8/10;
    padding: 0;
  }
  .dtContribCol,.dtCredits {
    font-size: 1.3em !important;
  }
  .dtInfoCol {
    font-size: 1.6em !important;
  }
  
}

@media (min-width: 2000px) {

  .p1 {
    grid-column: 1/4;
  }
  .p2 {
    grid-column: 8/11;
  }


  .dtButton{
    font-size: 2em !important;
    padding: 1.25rem .75rem;
    line-height: 1.2em !important;
  }
}

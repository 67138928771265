*, *:before, *:after {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 16px;

}

body {
  margin: 0;
  font: 15px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
}

main,
li {
  display: block;
}
h1,
h2,
h3,
h4 {
  font-size: inherit;
}
b,
strong {
	font-weight: bolder;
}
a,
button {
  color: inherit;
  transition: 0.3s;
}
a {
  text-decoration: none;
  background-color: transparent;
}
button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border: 0;
}
img {
  max-width: 100%;
  height: auto;
  border: 0;
  border-style: none;
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

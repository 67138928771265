@import "../../common/util.scss";

.headingWrap {
    width:100%;
    box-shadow: 0px 8px 20px var(--background-color), 0px 8px 20px var(--background-color);
    z-index: 999;

}

.heading{
    position: fixed;
    width: 90px;
    top:0;
    left: 0;
    opacity: 0.5;
    margin: var(--butMargin) 0px 0px var(--butMargin);
    transition: opacity .9s ease 0s;
}

.heading:hover{
    opacity: 1;
}

@media (max-width: 768px) {
    .heading{
        width: 50px;
        margin: var(--butMargin-sm) 0px 0px var(--butMargin-sm);
        position:relative;

    }
  }

@import "../../common/util.scss";

.container {
  @include mh(a);
  @include mb(xl);
  @include container;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  @include large {
    display: flex;
    flex-direction: row;
  }
}

.column {
  @include mv;
  @include mh(0);
  @include large {
    @include mh;
    flex: 1;
    &:first-child {
      @include ml(0);
    }
    &:last-child {
      @include mr(0);
    }
  }
}

.fullBleede {
  max-width: 100vw;
}

.container p:not(:last-child) {
  padding-bottom: 1em;
}

.container a {
  // text-decoration: underline;
}

.container a:hover {
  color: var(--bg-accent);
}
